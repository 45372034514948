import request from "../utils/request";

const token = localStorage.getItem('token')
/**
 * 登录
 */
export const getAuth=(username,password,acounttype)=>request({
  method:'POST',
  url:'/index/userLogin',
  params:{
    username,
    password,
    acounttype
  }
})
/**
 * 注册
 */
export const toRegister=(username,password,sendType,captcha,parent_invitation,vcode)=>request({
  method:'POST',
  url:'/index/userRegister',
  params: {
    username,
    password,
    sendType,
    captcha,
    parent_invitation,
    vcode
  }
})
/**
 * 获取登录图形二维码
 */
export const imgAuthCode=()=>request({
  method:'GET',
  url:'/index/getCaptcha',
  responseType:'blob',
})

/**
 * 发送注册邮箱/短信验证码
 */
export const getAuthCode=(codeType,sendType,address)=>request({
  method:'GET',
  url:'/index/sendPhoneOrEmailVcode',
  params:{codeType,sendType,address}
})

/**
 * 绑定邮箱或者手机号
 */
export const bindPhoneOrEmail=(codeType,sendType,modifyValue,vcode,originalVcode)=>request({
  method:'GET',
  url:'/index/bindPhoneOrEmail',
  params: {
    codeType,sendType,modifyValue,vcode,originalVcode
  }
})

/**
 * 用户修改个人资料(姓名和护照)
 */
export const changeUserInfo=(passport,name)=>request({
  method:'GET',
  url:'/index/editUserPassportInfo',
  params: {passport,name}
})

/**
 * 修改密码
 */
export const changePassword=(acounttype,username,old_password,new_password)=>request({
  method:'GET',
  url:'/index/modifyPwd',
  params: {acounttype,username,old_password,new_password}
})
/**
 * 用户修改的api信息
 */
export const changeUserApi=(apiKey,secretKey,vcode,apiType, vcodeType, ifConfirm)=>request({ //api类型 1:币安 2:okex
  method:'GET',
  url:'/index/bindBNApi',
  params: {apiKey,secretKey,vcode,apiType, vcodeType, ifConfirm}
})
/**
 * 用户修改的ok_api信息
 */
export const changeOkApi=(apiKey,secretKey,vcode,okexPassphrase,apiType, vcodeType, ifConfirm)=>request({ //api类型 1:币安 2:okex
  method:'GET',
  url:'/index/bindBNApi',
  params: {apiKey,secretKey,vcode,okexPassphrase,apiType, vcodeType, ifConfirm}
})
/**
 * 获取用户的qpi信息
 */
export const getUserApiInfo=()=>request({
  method:'GET',
  url:'/index/getBNApiInfo',
  params:{token}
})

/**
 * 获取用户的ok_qpi信息
 */
export const getOkApiInfo=()=>request({
  method:'GET',
  url:'/index/getOKApiInfo',
})

export const updateRobotSort=(apiType)=>request({
  method:'GET',
  url:'/index/updateRobotSort',
  params: {apiType}
})

/**
 * 解绑API
 * @param apiType
 * @returns {AxiosPromise}
 * @constructor
 */
export const ClearApi=(apiType)=>request({
  method: 'POST',
  url:'/index/ClearApi',
  params:{apiType}
})

/**
 * 充值密码
 */
export const resetPassword=(acounttype,username,password,vcode)=>request({
  method:'GET',
  url:'/index/resetPwd',
  params:{
    acounttype,
    username,
    password,
    vcode
  }
})

/**
 * 验证邮箱或者手机号是否存在
 */
export const getIsExistEmailOrPhone=(sendType,username)=>request({
  method:'GET',
  url:'/index/verificationAccount',
  params:{sendType,username}
})

/**
 * 意见反馈
 */
export const feedbackRequest = (category,content,picture,uid,requestTimes) => request({
  method: 'get',
  url: '/other/feedback',
  params:{category,content,picture,uid,requestTimes}
})

/**
 * 消息
 */
export const getFeedbackMsg = (requestTimes, pageNum, pageSize, uid, lastTime, firstTime) => request({
  method: 'POST',
  // url: '/index/getFeedbackMsg',
  // url: '/other/getFeedbackMsg',
  url: '/other/getFeedbackMsgList',
  params: {requestTimes, pageNum, pageSize, uid, lastTime, firstTime}
})

/**
 * 获取未读消息数量
 * @param requestTimes
 * @returns {AxiosPromise}
 */
export const getFeedbackCount = (requestTimes) => request({
  method: 'POST',
  url: 'other/getFeedbackCount',
  params:{requestTimes}
})

/**
 * 点卡列表
 */
export const selectPointCart = () =>request({
  method: 'GET',
  url: '/wallet/selectPointCart'
})

/**
 * 购买点卡新
 */
export const buyPointCardNew = (cardAmount, giveCard) =>request({
  method: 'GET',
  url: '/wallet/buyPointCardNew',
  params: {cardAmount, giveCard}
})
